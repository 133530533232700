<template>
  <div>
    <Loading :isLoading="isLoading" />
    <div>
      <p style="padding:10px 10px 0px 10px;font-size:15px;color:#810042;font-weight:700;font-family:Lato;">Reporte de Referidos (Invitaciones)</p>
    </div>

    <div class="py-2 xl:flex xl:flex-wrap gap-10">
      <div class="w-full xl:w-3/4 outline-none">
        <div class="">
          <TableComponent :key="render" :filters="filters" :columns="columns" :rows="rows" :rangeInput="true" :exportInput="true" :withStyle="false" :classContainer="'shadow-md border-2 rounded-lg py-2 px-0 focus:border-blue'" :buttonDown="{'text': `<svg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5 my-auto mr-3 ' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'><path stroke-linecap='round' stroke-linejoin='round' d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z' /></svg> Enviar Correo`, 'show': true}" v-on:rowsSelected="changeRowsSelected($event)" :filterInput="true" v-on:changeTableFilter="changeFilters($event)" v-on:clickButtonDown="clickButtonDown($event)" />
        </div>
        <div class="shadow-md border-2 rounded-lg mt-16 py-2 px-0 focus:border-blue">
          <label class="font-normal mx-5">Últimas notificaciones enviadas:</label>
          <TableComponent :key="renderHistoryReport" :columns="columnsHistory" :rows="rowsHistory" :rangeInput="false" :exportInput="false" :withStyle="false" :filterInput="false" />
        </div>
      </div>
      
      <div class="w-full mt-10 xl:mt-0 xl:w-1/5">
          <div class="outline-none shadow-md border-2 rounded-lg w-full py-2 px-5 border-vividOrange">
            <label class="px-3 py-5 text-lg font-medium	" style="color:#810042;">Historial de Referidos</label>

              <div class="w-full  border-2 rounded-lg shadow-lg mt-5 border-vividOrange" v-for="(data,index) in elementList" :key="index" >
                <div class="w-full px-5 py-2 flex" :class="{'cursor-pointer':data.show}" @click="redirectElement((data.nameUrl!== '')?`/${role}/${data.nameUrl}/referralhistory/0/0`: `/${role}/referralhistory`)">
                  <div class="w-4/5" style="color:#810042;">
                  <p class="text-4xl" style="color:#810042;">{{data.title}}</p>
                  <p class="" style="#810042;">{{data.text}}</p>
                </div>
                <div class="w-1/5 m-auto">
                <a v-if="data.show==true" > 
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chevron-right " viewBox="0 0 16 16" style="color:#810042;">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </a>
                </div>
                </div>
              </div>
          </div>
          <div class="outline-none shadow-md border-2 rounded-lg w-full py-5 px-5 mt-5 border-vividOrange">
            <label class="px-3 py-5 text-lg font-medium	" style="color:#810042;">Inscripción Asistida</label>

              <div class="w-full  border-2 rounded-lg shadow-lg mt-5 border-vividOrange" v-for="(data,index) in elementAssistedList" :key="index" >
                <div class="w-full px-5 py-2 flex" :class="{'cursor-pointer':data.show}" @click="redirectElement((data.nameUrl!== '')?`/${role}/${data.nameUrl}`: `/${role}/referralhistory`)">
                  <div class="w-4/5" style="color:#810042;">
                  <p class="text-4xl" style="color:#810042;">{{data.title}}</p>
                  <p class="" style="#810042;">{{data.text}}</p>
                </div>
                <div class="w-1/5 m-auto">
                <a v-if="data.show==true" > 
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chevron-right " viewBox="0 0 16 16" style="color:#810042;">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </a>
                </div>
                </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/tailwind.css';
import Referral from "@/classes/Referral.js";
import Loading from '@/components/Loading/VueLoading.vue';

//Iconos
import checkIcon from '@/assets/yes.svg';
import crossIcon from '@/assets/no.svg';

import TableComponent from "@/components/Forms/Tables/TableComponent";
import { onMounted, ref, getCurrentInstance } from 'vue-demi';
import Swal from 'sweetalert2';
import {getTokenDecoden} from "@/helpers/tokenauth";
import moment from "moment";
import { useRouter } from 'vue-router';
import FilterRangeTime from "@/components/Forms/Filters/FilterRangeTime.vue";

export default {
    name:"ReferralHistoryC",
    components:{
      Loading,
      TableComponent,
      FilterRangeTime
    },
    setup(){
      let isLoading=ref(false);
      const router = useRouter();

      let referral=new Referral();
      let app = getCurrentInstance();
  
      let referralHistoryList = ref([]);
      let columns = ref([]);
      let filters = ref([
        {"type": "select", "name": "referred_by", "label": "Referido por:", "values": []}
      ]);

      let columnsHistory = ref([]);
      let rows = ref([]);
      let rowsHistory = ref([]);
      let fields = ref([]);
      let fieldsHistory = ref([]);
      let render = ref(false);
      let renderHistoryReport = ref(false);

      let role = ref("");

      let dateSelectPicker=ref({dateStart:"",dateFinish:""});

      let userId = ref(0);
      let elementList=ref([
        {url:"",title:'0',text:"Total de Referidos",show:false,nameUrl:""},
        {url:"referals/get_not_sent",title:"",text:"Invitaciones no enviadas",show:true,nameUrl:"solicitudes-no-enviadas"},//no enviado
        {url:"referals/get_sent",title:"",text:"Invitaciones enviadas",show:true,nameUrl:"solicitudes-enviadas"},//enviado
        {url:"referals/get_open_link",title:"",text:"Solicitudes abiertas",show:true,nameUrl:"solicitudes-abiertas"},//abiertas
        {url:"referals/get_account_created",title:"",text:"Cuentas creadas",show:true,nameUrl:"solicitudes-cuentas-creadas"},
        {url:"referals/get_incomplete_request",title:"",text:"Solicitudes incompletas",show:true,nameUrl:"solicitud-incompleta"},
        {url:"referals/get_complete_request",title:"",text:"Solicitudes completadas",show:true,nameUrl:"solicitud-completa"},
        {url:"referals/get_accepted_request",title:"",text:"Solicitudes aceptadas",show:true,nameUrl:"solicitud-aceptada"},
        {url:"referals/get_rejected_request",title:"",text:"Solicitudes rechazadas",show:true,nameUrl:"solicitud-rechazada"},
      ]);

      let elementAssistedList=ref([
        {url:"",title:"0",text:"Total",show:true,nameUrl:""},
        {url:"",title:"0",text:"Solicitudes capturadas pendiente de revisión",show:true,nameUrl:``},
        {url:"",title:"0",text:"En revisión",show:true,nameUrl:``},
        {url:"",title:"0",text:"Aceptadas",show:true,nameUrl:``},
        {url:"",title:"0",text:"Rechazadas",show:true,nameUrl:``},
      ]);

      let historyList = ref([]);
      let filterDateActive = ref(null);
      const referredList = ref([]);
      const referredName = ref("");

      onMounted(async()=>{
        role.value=getTokenDecoden().obj['role'];
        app = app.appContext.config.globalProperties;

        await getAllHistory();
        getColumns();
        getRows();
        elementList.value[0].title = referralHistoryList.value.length;

        await quantityRegisters();
        render.value = true;

        await getListOfReferalsAssisted();
      });

      const changeFilters = async (values) => {
        renderHistoryReport.value = false;

        if(values.length > 0 && values[0].value != " "){
          const filter = values.filter(filter => filter.name == 'referred_by');
          const referred = filter[0].values.filter(row =>  filter[0].value == row.value)

          referredName.value = filter[0].value;
          await getHistoryReportToPromoter(referred[0].id);

          getColumnsHistoryReport();
          getRowsHistoryReport();

          renderHistoryReport.value = true;
          userId.value = referred[0].id;
          
          await getListOfReferalsAssisted(referred[0].id);
        }else{
          userId.value = 0;
          await getListOfReferalsAssisted();
        }
      }

      const clickButtonDown = (value) => {
        var swal = require('sweetalert2')

        swal.fire({
          title: "Aviso",
          text: `¿Desea enviar el correo de reporte de referidos?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "SI",
          cancelButtonText: "NO",
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
        })
        .then((result) => {
          
          if (result.value) {
            if(userId.value == "" || userId.value == 0){
              swal.fire(
                'Aviso',
                'Para enviar el reporte debe seleccionar un referidor',
                'info'
              )
              return;
            }

            sendEmail(userId.value);
          }
        });
      }

      const changeRowsSelected = (value) => {
        console.log("emails", value)
        var swal = require('sweetalert2')

        swal.fire({
          title: "Aviso",
          text: `¿Desea enviar el correo de reporte de referidos?`,
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "SI",
          cancelButtonText: "NO",
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
        })
        .then((result) => {
          if (result.value) {
            sendEmail(value);
          }
        });

      }

      const sendEmail = async (userId) => {

        isLoading.value = true;
        referral.post({
            "token_auth":sessionStorage.getItem("login"),
            "user_id": userId
        },"referals/send_report_to_promoter").then(resp => resp).catch(err => {
            console.log("Error:", err);
            isLoading.value = false;
        });

        isLoading.value = false;

        var swal = require('sweetalert2')
        swal.fire({
          title: "Aviso",
          html: 'Reporte enviado correctamente',
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: '#FEB72B'
        });
      }

      async function quantityRegisters(){
        isLoading.value=true;
        try {
          const elements = elementList.value.filter(obj => obj.url != "")
          elements.forEach(async(e)=>{
            console.log("url:", e.url);
            const _res=await referral.get({
              "token_auth": sessionStorage.getItem("login"),
              "id": null
            },`${e.url}`);
            e.title=_res.data.response.data.length;
          })
          isLoading.value=false;
        } catch (error) {
          isLoading.value=false;
          console.log(error)
          Swal.fire({
            icon:"error",
            title:"Error",
            text:"Ocurrio un error inesperado."
          });
        }
      }

      async function getAllHistory(){
        isLoading.value=true;
        try {
          const _response=await referral.get({
            "token_auth":sessionStorage.getItem("login"),
            "start_date":"2019-01-01",
            "end_date": moment(new Date()).format('YYYY-MM-DD')
          },"referals/get_all_status_with_pagination");

          isLoading.value=false;
           if(_response.data.response.has_errors){
              let messages="";
              _response.data.errors.forEach(element => {
                messages+="<strong>■ "+element+".</strong><br>"
              });
              Swal.fire({
                title: "Aviso",
                html:_response.data.response.message+"<br>"+messages,
                icon: "info",
                confirmButtonText: "OK",
                confirmButtonColor: '#FEB72B'
              }); 
              }else{
                isLoading.value=false;
                let res=_response.data.response.referals.rows;
                res.forEach(element=>{
                    if(referredList.value && element.fonsnte_user_id!=null){
                      referredList.value[element.fonsnte_user_id] = {id: element.fonsnte_user_id, value: element.user!=null?`${element.user.person[0].name} ${element.user.person[0].middle_name} ${element.user.person[0].maternal_name} ${element.user.person[0].paternal_name}`:"", name: element.user!=null?`${element.user.person[0].name} ${element.user.person[0].middle_name} ${element.user.person[0].maternal_name} ${element.user.person[0].paternal_name}`:""};
                    }
                    
                    referralHistoryList.value.push(new Object({
                      name: element.name,
                      cellphone:element.cellphone,
                      email:element.email,
                      invitation_date:element.admission_request!=null?element.admission_request.is_assisted_request==true? element.admission_request.created_at:"N/A":"N/A",
                      status:element.status,
                      registration_date:element.created_at,
                      referred_by:element.user!=null?`${element.user.person[0].name} ${element.user.person[0].middle_name} ${element.user.person[0].maternal_name} ${element.user.person[0].paternal_name}`:"",
                      email_sent:element.total_sent==1?"Si":"No",
                      referenced_user_id: element.referenced_user_id
                    }))

                });

                filters.value[0].values = [...new Set(referredList.value)];
              }
          }catch (error) {
            isLoading.value=false;
            console.log(error)
            Swal.fire({
              icon:"error",
              title:"Error",
              text:"Ocurrio un error inesperado."
            });
            
          }
      }

      async function getHistoryReportToPromoter(promoter_id){
        const data = await referral.get({
          "token_auth":sessionStorage.getItem("login"),
          "user_id": promoter_id
        },"referals/get_history_report_to_promoter").then(resp => resp.data.response.data).catch(err => console.log("Error:", err));
        
        historyList.value = getFinalArrayList(data);
      }

      async function getListOfReferalsAssisted(user_id=""){
        var totals = {
          assited: [],
          capturedRequests: [],
          inRevision: [],
          accepted: [],
          rejected: []
        };

        let referralList = await referral.get({
          "token_auth":sessionStorage.getItem("login")
        },"referals/list_of_referals_assisted").then(resp => resp.data.response.referals).catch(err => console.log("Error:", err));
        
        referralList = referralList.filter( referral => {
          if(user_id != '') {
            if(referral.fonsnte_user_id == user_id){
              return referral;
            }
          } else {
            return referral;
          }
        });

        totals.assited = referralList.length;

        referralList.forEach( referral => {
          if(referral.admission_request.status == 'pendiente_de_revision'){
            totals.capturedRequests.push(referral);
          }
          if(referral.admission_request.status == 'en_revision'){
            totals.inRevision.push(referral);
          }
          if(referral.admission_request.status == 'aceptada'){
            totals.accepted.push(referral);
          }
          if(referral.admission_request.status == 'rechazada'){
            totals.rejected.push(referral);
          }
        });

        elementAssistedList.value[0].title = totals.assited;
        elementAssistedList.value[1].title = totals.capturedRequests.length;
        elementAssistedList.value[2].title = totals.inRevision.length;
        elementAssistedList.value[3].title = totals.accepted.length;
        elementAssistedList.value[4].title = totals.rejected.length;

        elementAssistedList.value[0].nameUrl = `solicitudes-asistida-capturadas/referralhistory/todas/${userId.value}`;
        elementAssistedList.value[1].nameUrl = `solicitudes-asistida-capturadas/referralhistory/pendiente_de_revision/${userId.value}`;
        elementAssistedList.value[2].nameUrl = `solicitud-asistida-en-revision/referralhistory/en_revision/${userId.value}`;
        elementAssistedList.value[3].nameUrl = `solicitud-asistida-aceptadas/referralhistory/aceptada/${userId.value}`;
        elementAssistedList.value[4].nameUrl = `solicitud-asistida-rechazadas/referralhistory/rechazada/${userId.value}`;
      }

    const getFinalArrayList = (array) => {
      let finalArray = [];
      try {
        for (let i = 0; i < array.length; i++) {
         
          var id = array[i].id;
          var name = referredName.value;
          var created_at = app.$filters.formatDate(array[i].created_at);
          var remote_path = {
            name: array[i].name,
            extension: array[i].extension
          };

          var link = array[i].remote_path;

          finalArray.push({
            id: id,
            name: name,
            created_at: created_at,
            remote_path: remote_path,
            link: link
          })
        }
      } catch (error) {
        console.log(error)
      }
      return finalArray;
    }

    const getColumns = () => {
      if(referralHistoryList.value.length == 0){
        return;
      }
      columns.value = [
        { headerName: "Nombre", field: 'name', headerCheckboxSelection: true, checkboxSelection: true },
        { headerName: "Teléfono", field: 'cellphone' },
        { headerName: "Correo", field: 'email' },
        { headerName: "Fecha de invitación", field: 'invitation_date', filter: true},
        { headerName: "Estatus", field: 'status' },
        { headerName: "Fecha de inscripción", field: 'registration_date', filter: true, filterParams: filterParams },
        { headerName: "Referido por:", field:"referred_by", filter: false },
        { headerName: "Correo Enviado", field:"email_sent", cellRenderer: params => isCheckIcon(params) },
      ];
    
      columns.value.forEach(element => {
        fields.value.push(element.field);
      });
    }

    var filterParams = {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        filterDateActive.value = true;
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split('/');
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
    };

    const getColumnsHistoryReport = () => {
     
      columnsHistory.value = [
        { headerName: "Referidor", field: 'name' },
        { headerName: "Fecha de envío de correo", field: 'created_at' },
        { headerName: "Reporte", field: 'remote_path', width: 400, cellRenderer: params => {

          if(params.value){
            const text = document.createElement("label");
            text.innerText = `${params.value.name}.${params.value.extension}`;
            text.className += "cursor-pointer";
            return text;
          }

        }},
        { headerName: "", field: "link", filter: false, width: 350,cellRenderer: params => {
          
          const link = document.createElement("a");
          link.href = params.value;
          link.innerText = "Descargar";
          link.className += "bg-mustard text-white focus:bg-blue-700	px-10 py-1.5 mx-auto rounded-md cursor-pointer";
          return link;
        }}
      ];

      columnsHistory.value.forEach(element => {
        fieldsHistory.value.push(element.field);
      });
    }
    const getRows = () => {
      if(referralHistoryList.value.length == 0){
        return;
      }
      rows.value = referralHistoryList.value.filter((admission) => {
          for (let i in admission) {
            if(!fields.value.includes(i)){
              delete admission[i];
            }
          }
          
          return formatFields(admission);
      });
    }

    const getRowsHistoryReport = () => {
      
      rowsHistory.value = historyList.value.filter((admission) => {
          for (let i in admission) {
            if(!fieldsHistory.value.includes(i)){
              delete admission[i];
            }
          }
          
          return formatFields(admission);
      });
    }

    const isCheckIcon = (params) => {
      const value = params.value=="Si" ? `<img class="mx-auto" src=${checkIcon} loading="lazy" />`:`<img class="mx-auto" src=${crossIcon} loading="lazy"/>`;
      return value;
    }

    const formatFields = (fields) => {
      const statusFields = ['proceso'];
      const lowercaseFields = ['email',"status"];
      const dateFields = ['invitation_date',"registration_date"];
      const phoneFields = ['cellphone'];
      const uppercaseFields = ['curp',"name"];
      
      for(let i in fields){
        if(lowercaseFields.includes(i)){
          fields[i] = fields[i].toString().toLowerCase();
        }else if(uppercaseFields.includes(i)){
          fields[i] = fields[i].toString().toUpperCase();
        }else if(dateFields.includes(i)){
          fields[i] = app.$filters.formatDate(fields[i]);
        }else if(phoneFields.includes(i)){
          fields[i] = app.$filters.formatPhone(fields[i]);
        }else if(statusFields.includes(i)){
          fields[i] = app.$filters.requestStatus(fields[i]);
        }
      }
      return fields;
    }

    function getDatePicker(event,picker){
      switch(picker){
        case "1":
            dateSelectPicker.value.dateStart=moment(event._d).format("DD-MM-yyyy");        
          break;
        case "2":
            dateSelectPicker.value.dateFinish=moment(event._d).format("DD-MM-yyyy");
          break;
      }
      dateValidation();
    }

    function dateValidation(){
      if(dateSelectPicker.value.dateStart!="" && dateSelectPicker.value.dateFinish!=""){
        if(dateSelectPicker.value.dateStart>dateSelectPicker.value.dateFinish){
          Swal.fire({
            icon:"warning",
            title:"Cuidado.!",
            text:"La fecha inició es mayor que la fecha final."
          });
          dateSelectPicker.value.dateFinish="";
        }
      }
    }

    function redirectElement(data){
      router.push({path:data});
    }


    return {
      isLoading,
      columns,
      rows,
      render,
      elementList,
      elementAssistedList,
      role,
      getDatePicker,
      dateSelectPicker,
      redirectElement,
      columnsHistory,
      rowsHistory,
      changeRowsSelected,
      renderHistoryReport,
      filters,
      changeFilters,
      userId,
      clickButtonDown
    }
        

    }
}
</script>

<style>
.vpd-input-group input{
  border: 2px solid rgba(205, 205, 205, 0.416) !important;
  border-start-end-radius: 10px;
  border-end-end-radius: 10px;
  padding: 5px !important;
  box-shadow: 0px 5px 10px 0px  rgba(205, 205, 205, 0.416) !important;
}

.vpd-input-group input:focus{
  /* border: 2px solid rgba(205, 205, 205, 0.416) !important;
  border-start-end-radius: 10px;
  border-end-end-radius: 10px;
  padding: 5px !important;
  box-shadow: 0px 5px 10px 0px  rgba(205, 205, 205, 0.416) !important; */
  outline: #810042 !important;
}

</style>
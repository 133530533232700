<template>
<div class="flex flex-col">
  <footer class="" style="height:60px;background:#F8F8F8;">
    <div class="grid grid-cols-2 gap-5 m-5">
      <div style="font-family:Lato;text-align:right;font-size:13px;">
        <a href="" >Contacto</a>
      </div>
      <div style="font-family:Lato;font-size:13px;">
        <a href="">Soporte</a>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 m-5">
      <div style="text-align:center;font-family:Lato;font-size:14px;">
        <p>© 2021 FONCABSA. Todos los derechos reservados.</p>
      </div>
    </div>
  </footer>
</div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>
<style scoped>

.footer_container {
  
}
.img_container {
  
}
.img_menu {
  
}
@screen sm {
  
}
footer {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>

<template>
    <div class="mx-2 my-3">
        <div class="filter-title">{{params.title}}</div>
        <select class="mt-3 ag-input-field-input ag-text-field-input border rounded" name="selectRange" id="selectRange" v-model="filterState" @change="updateFilter()">
            <option v-for="(value, index) in params.values" :value="value.value" :key="index">{{ value.text }}</option>
        </select>
    </div>
</template>

<script setup>
import {ref} from "@vue/reactivity";
const { params } = defineProps();
const {field} = params.colDef

console.log(params)

const filterState = ref("Filter Off");

function updateFilter() {
    console.log("updateFilter:",params);
    params.filterChangedCallback();
}
function isFilterActive() {
    console.log("isFilterActive:",params);
    return filterState.value != 'Filter Off';
}
function doesFilterPass(params) {
    
    let row = params.data[field].toString().split('/');
    row = new Date(row[2], row[1], row[0]);

    let initialDate = filterState.value.initial.toString().split('/');
    initialDate = new Date(initialDate[2], initialDate[1], initialDate[0]);

    let finalDate = filterState.value.final.toString().split('/');
    finalDate = new Date(finalDate[2], finalDate[1], finalDate[0]);
      
    return initialDate <= row  && finalDate >= row;
}
function getModel() {
    console.log("getModel:");
    if (filterState.value == ""){
        return undefined;
    }
    return {state: filterState.value};
}
function setModel(model){
    console.log("setModel:",model);
    if(model == null){
        updateFilter("");
    } else {
        updateFilter(model.state);
    }
}
function getModelAsString() {
    console.log("getModelAsString:");
    return filterState.value == ""? "": filterState.value.initial+"-"+filterState.value.final
}
function onNewRowsLoaded(){

}

</script>

<style lang="scss" scoped>
</style>